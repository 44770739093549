import { Buffer } from 'buffer';
import process from 'process';
import crypto from 'crypto';

window.Buffer = Buffer;
window.process = process;

if (typeof window.crypto === 'undefined') {
  window.crypto = {
    getRandomValues: function(buffer) {
      return require('crypto').randomFillSync(buffer);
    }
  };
}