import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { ethers, providers } from 'ethers';
import { useAccount, useConnect, useDisconnect, useNetwork, useChainId, usePublicClient, useWalletClient, useSignMessage, useSwitchNetwork } from 'wagmi';
import { Web3Button, useWeb3Modal } from '@web3modal/react';
import { publicProvider } from 'wagmi/providers/public';
import { parseEther, formatEther } from 'viem';
import { createPublicClient, http } from 'viem'
import { mainnet } from 'viem/chains'
import axios from 'axios';
import './polyfills';
import { config, ethereumClient } from './web3Config';
import { Container, Row, Col, Card, Button, ListGroup, Badge, ProgressBar, Spinner, Form, InputGroup, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faCheckSquare, faChartLine, faExternalLinkAlt, faTimes, faLink, faCoins, faSync, faPowerOff, faPencilAlt} from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import { signMessage } from '@wagmi/core';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/global.css';
import './Dustbin.css';
import TokenCache from './TokenCache.js';
import GasGauge from './components/GasGauge.js';
import StatsCounter from './components/StatsCounter.js';
//const RPC_URL = 'https://side-patient-market.quiknode.pro/45c001ae848811224fc94383448afabc02ba3b42/';
const RPC_URL = 'https://ethnode.dustbin.pro:8443';

const dappVersion = '2.0.4';

// handle VIP codes here
const VALID_VIP_CODES = ['DUSTBINMOFO','CHEDDERCHEESE'];
const DEFAULT_IS_VIP = false;

const customPublicClient = createPublicClient({
  chain: mainnet,
  transport: http(RPC_URL)
})

const ETHERSCAN_API_KEY = process.env.REACT_APP_ETHERSCAN_API_KEY;
const ETHERSCAN_API_URL = 'https://api.etherscan.io/api';
const BATCH_SELL_REGULAR_ADDRESS = '0x57c1313D06d7824769158167620d2Ec8B9A21E50';
const BATCH_SELL_VIP_ADDRESS = '0x0160DEA3B730f7486a645983C8b0B000f5d40a00';
const WETH_ADDRESS = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2';
const UNISWAP_ROUTER_ADDRESS = '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D';
const MAX_SELECTED_TOKENS = 20;
const STORAGE_KEY = 'dustbin_destination_wallet';

const SIMULATION_API_KEY = process.env.REACT_APP_SIMULATION_API_KEY;
const SIMULATION_API_URL = 'https://sim.dustbin.pro:3000/simulate-sells';
const APP_LANDING_URL = 'https://dustbin.pro';

const SIGNER_PRIVATE_KEY = process.env.REACT_APP_SIGNER_PRIVATE_KEY;

const BATCH_SELL_ABI = [
  {
    name: "batchSellTokens",
    type: "function",
    inputs: [
      { name: "tokens", type: "address[]" },
      { name: "amounts", type: "uint256[]" },
      { name: "minEthAmounts", type: "uint256[]" },
      { name: "isFeeOnTransfer", type: "bool[]" },
      { name: "routerTypes", type: "uint8[]" },
      { name: "feeTiers", type: "uint24[]" },
      { name: "referrer", type: "address" },
      { name: "timestamp", type: "uint256" },
      { name: "signature", type: "bytes" },
      { name: "destinationWallet", type: "address" }
    ],
    outputs: [],
    stateMutability: "nonpayable"
  },
  {
    name: "feePercentage",
    type: "function",
    inputs: [],
    outputs: [{ type: "uint256" }],
    stateMutability: "view"
  },
  {
    name: "ERC20Withdrawn",
    type: "event",
    inputs: [
      { indexed: true, name: "token", type: "address" },
      { indexed: true, name: "to", type: "address" },
      { indexed: false, name: "amount", type: "uint256" }
    ]
  },
  {
    name: "FeesWithdrawn",
    type: "event",
    inputs: [
      { indexed: true, name: "owner", type: "address" },
      { indexed: false, name: "amount", type: "uint256" }
    ]
  }
];

const ERC20_ABI = [
  {
    name: "balanceOf",
    type: "function",
    inputs: [{ name: "owner", type: "address" }],
    outputs: [{ name: "balance", type: "uint256" }],
    stateMutability: "view"
  },
  {
    name: "decimals",
    type: "function",
    inputs: [],
    outputs: [{ name: "", type: "uint8" }],
    stateMutability: "view"
  },
  {
    name: "symbol",
    type: "function",
    inputs: [],
    outputs: [{ name: "", type: "string" }],
    stateMutability: "view"
  },
  {
    name: "name",
    type: "function",
    inputs: [],
    outputs: [{ name: "", type: "string" }],
    stateMutability: "view"
  },
  {
    name: "approve",
    type: "function",
    inputs: [
      { name: "spender", type: "address" },
      { name: "amount", type: "uint256" }
    ],
    outputs: [{ name: "", type: "bool" }],
    stateMutability: "nonpayable"
  },
  {
    name: "allowance",
    type: "function",
    inputs: [
      { name: "owner", type: "address" },
      { name: "spender", type: "address" }
    ],
    outputs: [{ name: "", type: "uint256" }],
    stateMutability: "view"
  }
];

const UNISWAP_ROUTER_ABI = [
  {
    inputs: [
      { name: "amountIn", type: "uint256" },
      { name: "path", type: "address[]" }
    ],
    name: "getAmountsOut",
    outputs: [{ name: "amounts", type: "uint256[]" }],
    stateMutability: "view",
    type: "function"
  }
];

const UNISWAP_V3_PAIR_ABI = [
  "function fee() external view returns (uint24)"
];

const RouterType = {
  UniswapV2: 0,
  SushiSwap: 1,
  ShibaSwap: 2,
  UniswapV3: 3
};

const mapHoneypotRouterToEnum = (honeypotRouter) => {
  switch (honeypotRouter.toLowerCase()) {
    case 'uniswapv2':
      return RouterType.UniswapV2;
    case 'sushiswapv2':
      return RouterType.SushiSwap;
    case 'shibaswap':
      return RouterType.ShibaSwap;
    case 'uniswapv3':
      return RouterType.UniswapV3;
    default:
      console.warn(`Unknown router type: ${honeypotRouter}. Defaulting to UniswapV2.`);
      return RouterType.UniswapV2;
  }
};

const PROXY_IPS = [
  'pr1.dustbin.pro',
  'pr2.dustbin.pro',
  'pr3.dustbin.pro'
];

let currentIpIndex = 0;


function getNextIp() {
  const ip = PROXY_IPS[currentIpIndex];
  currentIpIndex = (currentIpIndex + 1) % PROXY_IPS.length;
  return ip;
}

const generateSignature = async (user, referrer, timestamp) => {
  console.log('Generating signature for:', { user, referrer, timestamp });

  try {
    if (!SIGNER_PRIVATE_KEY) {
      throw new Error('SIGNER_PRIVATE_KEY is not defined');
    }

    const wallet = new ethers.Wallet(SIGNER_PRIVATE_KEY);
    console.log('Signer address:', wallet.address);

    const messageHash = ethers.utils.solidityKeccak256(
      ['address', 'address', 'uint256'],
      [user, referrer, timestamp]
    );
    console.log('Message hash:', messageHash);

    const signature = await wallet.signMessage(ethers.utils.arrayify(messageHash));
    
    console.log('Generated signature:', signature);
    return signature;
  } catch (error) {
    console.error('Error generating signature:', error);
    throw error;
  }
};

const Dustbin = () => {
  const [tokens, setTokens] = useState([]);
  const [selectedTokens, setSelectedTokens] = useState({});
  const [processingStatus, setProcessingStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [expectedEthAmount, setExpectedEthAmount] = useState('0');
  const [showProcessingDetails, setShowProcessingDetails] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [minEth, setMinEth] = useState('0.01');
  const [maxEth, setMaxEth] = useState('∞');
  const [filteredTokens, setFilteredTokens] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [showProcessingCard, setShowProcessingCard] = useState(false);
  const [ethUsdRate, setEthUsdRate] = useState(null);
  const [processedTokens, setProcessedTokens] = useState([]);
  const [loadingProgress, setLoadingProgress] = useState({ current: 0, total: 0, message: '' });
  const [processedAddresses, setProcessedAddresses] = useState(new Set());
  const [gasPrice, setGasPrice] = useState(0);
  const [referralToken, setReferralToken] = useState(null);
  const [referrerAddress, setReferrerAddress] = useState(null);
  const [isCheckingReferral, setIsCheckingReferral] = useState(false);
  const [destinationWallet, setDestinationWallet] = useState('');
  const [showDestinationModal, setShowDestinationModal] = useState(false);
  const [newDestinationWallet, setNewDestinationWallet] = useState('');
  const [isVip, setIsVip] = useState(DEFAULT_IS_VIP);
  const toastShown = useRef(false);

  const { address, isConnected } = useAccount();
  const { connect } = useConnect();
  const { disconnect } = useDisconnect();
  const { chain } = useNetwork();
  const chainId = useChainId();
  const publicClient = customPublicClient
  const { data: walletClient } = useWalletClient();
  const { signMessageAsync } = useSignMessage();
  const { switchNetwork } = useSwitchNetwork();


  const BATCH_SELL_ADDRESS = useMemo(() => {
    return isVip ? BATCH_SELL_VIP_ADDRESS : BATCH_SELL_REGULAR_ADDRESS;
  }, [isVip]);
  
  const SERVICE_FEE = useMemo(() => {
    return isVip ? 2 : 7.5;
  }, [isVip]);

  const checkVipCode = useCallback(async () => {
    if (toastShown.current) return; // Early return if toast already shown
    
    const params = new URLSearchParams(window.location.search);
    const vipCode = params.get('v');
    
    if (!vipCode) return; // Early return if no code
    
    try {
      const response = await axios.post('/api/validate-vip-code', {
        code: vipCode
      });
      
      if (response.data.isValid) {
        setIsVip(true);
        toastShown.current = true; // Set before showing toast
        toast.success('VIP access granted! 🎉', {
          toastId: 'vip-access', // Add unique ID to prevent duplicates
        });
      } else {
        toast.error('Invalid VIP code.', {
          toastId: 'vip-error'
        });
      }
    } catch (error) {
      console.error('Error validating VIP code:', error);
    }
  }, []); // Empty dependency array

  const saveDestinationWallet = (wallet) => {
    localStorage.setItem(STORAGE_KEY, wallet);
  };
  
  const fetchGasPrice = async () => {
    try {
      const response = await axios.get(ETHERSCAN_API_URL, {
        params: {
          module: 'gastracker',
          action: 'gasoracle',
          apikey: ETHERSCAN_API_KEY
        }
      });
      if (response.data.status === '1') {
        const gweiPrice = parseInt(response.data.result.ProposeGasPrice);
        setGasPrice(gweiPrice);
        return gweiPrice;
      } else {
        console.error('Error fetching gas price:', response.data.message);
        return null;
      }
    } catch (error) {
      console.error('Error fetching gas price:', error);
      return null;
    }
  };
  
  useEffect(() => {
    fetchGasPrice();
    const interval = setInterval(fetchGasPrice, 10000); // Update every 10 seconds
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const storedReferrerAddress = localStorage.getItem('referrerAddress');
    if (storedReferrerAddress && ethers.utils.isAddress(storedReferrerAddress)) {
      setReferrerAddress(storedReferrerAddress);
    }
  }, []);

  const clearReferrerAddress = () => {
    localStorage.removeItem('referrerAddress');
    setReferrerAddress(null);
  };

  const updateProgress = useCallback((current, total, message) => {
    setLoadingProgress(prev => ({ 
      ...prev, 
      current: current,
      total: total,
      message: message
    }));
  }, []);

  const getUrlParameter = (name) => {
    console.log('getUrlParameter called with name:', name);
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(window.location.search);
    const result = results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    console.log(`URL parameter ${name}:`, result);
    return result;
  };

  const checkReferralToken = useCallback(async (walletAddress) => {
    if (!walletAddress) { walletAddress = address; }
    const token = getUrlParameter('ref');

    try {
      let response;
      if (token) {
        response = await axios.get(`/api/validate-referral-token/${token}?walletAddress=${walletAddress}`);
      } else {
        response = await axios.get(`/api/validate-referral-token?walletAddress=${walletAddress}`);
      }

      if (response.data.valid) {
        console.log('referral check response: ', response.data);
        const newReferrerAddress = response.data.referrerAddress;
        if (newReferrerAddress.toLowerCase() !== walletAddress.toLowerCase()) {
          localStorage.setItem('referrerAddress', newReferrerAddress);
          setReferrerAddress(newReferrerAddress);
          console.log('Valid referral. Referrer address:', newReferrerAddress);
          if (token) {
            toast.success('Referral applied successfully!');
          }
        } else {
          console.log('Referral belongs to the current account. Not applying.');
          toast.warning('You can\'t use your own referral code.');
        }
      } else {
        console.log('No valid referral found.');
        localStorage.removeItem('referrerAddress');
        setReferrerAddress(null);
      }
    } catch (error) {
      console.error('Error checking referral:', error);
      toast.error('Error checking referral. Please try again.');
    }
  }, []);

  const generateReferralToken = async () => {
    if (!address) {
      toast.error('Please connect your wallet first');
      return;
    }
  
    try {
      const response = await axios.post('/api/generate-referral-token', { userAddress: address });
      setReferralToken(response.data.referralToken);
      toast.success('Referral code generated successfully');
    } catch (error) {
      console.error('Error generating referral code:', error);
      toast.error('Failed to generate referral code');
    }
  };

  const getReferralTokenFromBackend = async (referrerAddress) => {
    try {
      const response = await axios.post('/api/generate-token', { referrerAddress });
      return response.data; // { timestamp, signature }
    } catch (error) {
      console.error('Error generating token:', error);
      return null;
    }
  };

  const simulateTokens = async (tokensToSimulate) => {
    console.log('Entering simualteTokens with tokensToSimulate: ', tokensToSimulate);
    const payload = {
      walletAddress: address,
      tokens: tokensToSimulate.map(token => {
        const amount = ethers.utils.parseUnits(token.balance.toString(), token.decimal);
        return {
          address: token.address.toLowerCase(),
          amount: amount.toString(),
          isFeeOnTransfer: token.hasFees || false,
          routerType: token.routerType || 0,
          feeTier: token.feeTier || 0
        };
      })
    };
  
    try {
      const response = await axios.post(SIMULATION_API_URL, payload, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': SIMULATION_API_KEY
        }
      });
  
      return response.data;
    } catch (error) {
      console.error('Error simulating tokens:', error);
      return null;
    }
  };

  const disconnectWallet = useCallback(() => {
    disconnect();
    setSelectedTokens({});
    setIsProcessing(false);
    setShowProcessingCard(false);
    setExpectedEthAmount('0');
    setProcessedTokens([]);
    setIsLoading(false);
    clearReferrerAddress(); // Make sure you have this function to clear the referrer
    toast.info("Wallet disconnected.");
  }, [disconnect]);

  const getTokenPrice = useCallback(async (tokenAddress, amount, decimals) => {
    if (!publicClient) {
      console.error('Provider not available');
      return 0;
    }

    const amountIn = ethers.utils.parseUnits(amount, decimals);
    const path = [tokenAddress, WETH_ADDRESS];
    
    try {
      const amounts = await publicClient.readContract({
        address: UNISWAP_ROUTER_ADDRESS,
        abi: UNISWAP_ROUTER_ABI,
        functionName: 'getAmountsOut',
        args: [amountIn, path],
      });

      console.log(`Amounts returned from Uniswap:`, amounts);
      const ethAmount = ethers.utils.formatEther(amounts[1]);
      console.log(`Formatted ETH amount: ${ethAmount}`);
      const result = parseFloat(ethAmount);
      console.log(`ETH value of ${tokenAddress} tokens held in wallet: ${result}`);
      return result;
    } catch (error) {
      console.error(`Error getting price for token ${tokenAddress}:`, error);
      return 0;  // Return 0 if we can't get the price
    }
  }, [publicClient]);

  const fetchEthUsdRate = async () => {
    try {
      const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd');
      setEthUsdRate(response.data.ethereum.usd);
    } catch (error) {
      console.error('Error fetching ETH/USD rate:', error);
    }
  };

  const ethToUsd = (ethAmount) => {
    if (!ethUsdRate) return null;
    const usdAmount = parseFloat(ethAmount) * ethUsdRate;
    return usdAmount.toFixed(2);
  };

  const fetchHoneypotData = async (tokenAddress, symbol, retries = 5, delay = 500, maxDelay = 1000) => {
    // Check database cache first
    const cachedToken = await TokenCache.getToken(tokenAddress);
    if (cachedToken) {
      console.log(`Using cached data for ${tokenAddress}`);
      return cachedToken;
    }
    else {
      console.log(`Cached data not found, looking up for ${tokenAddress}`);
    }
    
    try {
      const proxyIp = getNextIp();
      const proxyUrl = `https://${proxyIp}/?target=http://api.honeypot.is/v2/IsHoneypot?address=${encodeURIComponent(tokenAddress)}`;
      console.log(`Fetching honeypot data for ${tokenAddress} from proxy URL: ${proxyUrl}`);
      const response = await axios.get(proxyUrl);
      const honeypotData = response.data;

      console.log(`Honeypot data for ${tokenAddress}:`, honeypotData);

      if (honeypotData.code === 404 && honeypotData.error === "No pairs found") {
        console.log(`No pairs found for ${tokenAddress}, marking as invalid`);
        await TokenCache.saveToken({
          address: tokenAddress,
          symbol: symbol,
          isHoneypot: true,
          liquidity: 0,
          routerType: 0,
          routerAddress: '0x0000000000000000000000000000000000000000',
          feeTier: 0,
          hasFees: false,
          isInvalid: true,
          sellTax: 0,
          buyTax: 0,
          transferTax: 0
        });
        return null;
      }

      if (!honeypotData.honeypotResult || typeof honeypotData.honeypotResult.isHoneypot === 'undefined') {
        throw new Error('Unexpected API response structure');
      }

      // Save to database cache
      const tokenData = {
        address: tokenAddress,
        symbol: honeypotData.token?.symbol || symbol,
        isHoneypot: honeypotData.honeypotResult.isHoneypot,
        liquidity: honeypotData.pair?.liquidity || 0,
        routerType: mapHoneypotRouterToEnum(honeypotData.pair.pair.type) || 0,
        routerAddress: honeypotData.router || '0x0000000000000000000000000000000000000000',
        feeTier: honeypotData.pair?.pair?.fee || 0,
        hasFees: (honeypotData.simulationResult?.buyTax > 0 || honeypotData.simulationResult?.sellTax > 0 || honeypotData.simulationResult?.transferTax > 0) || false,
        isInvalid: false,
        buyTax: honeypotData.simulationResult?.buyTax || 0,
        sellTax: honeypotData.simulationResult?.sellTax || 0,
        transferTax: honeypotData.simulationResult?.transferTax || 0
      };

      console.log('token data before saving to database: ', tokenData);
      await TokenCache.saveToken(tokenData);

      return tokenData;      
    } catch (error) {
      console.error(`Error fetching honeypot data for ${tokenAddress}:`, error);
      
      if (retries > 0) {
        const isRateLimitError = error.response && error.response.status === 429;
        const isInsufficientResourcesError = error.message.includes('ERR_INSUFFICIENT_RESOURCES');
        const isNetworkError = error.message === 'Network Error';
        
        if (isRateLimitError || isInsufficientResourcesError || isNetworkError) {
          console.log(`Retrying fetch for ${tokenAddress} in ${delay}ms...`);
          await new Promise(resolve => setTimeout(resolve, delay));
          return fetchHoneypotData(
            tokenAddress, 
            retries - 1, 
            Math.min(delay * 2, maxDelay),
            maxDelay
          );
        }
      }
      
      // If all retries are exhausted or it's not a retryable error, save a default token data
      await TokenCache.saveToken({
        address: tokenAddress,
        symbol: symbol,
        isHoneypot: true,
        liquidity: 0,
        routerType: 0,
        routerAddress: '0x0000000000000000000000000000000000000000',
        feeTier: 0,
        hasFees: false,
        isInvalid: true,
        sellTax: 0,
        buyTax: 0,
        transferTax: 0
      });
      
      return null;
    }
  };

  const fetchTokens = useCallback(async () => {
    if (!address || !publicClient) return;
    let currentProcessedTokens = [];
    setIsLoading(true);
    setShowProcessingCard(false);
    setProcessedTokens([]); // Clear previously processed tokens
    updateProgress(0, 0, 'Fetching token transactions...');

    // Get VIP code from URL
    const params = new URLSearchParams(window.location.search);
    const vipCode = params.get('v');
    console.log('vipCode: ', vipCode);

    try {
      await axios.post('/api/save-wallet', { walletAddress: address, vipCode: vipCode || null });
      console.log('Wallet information saved successfully');
    } catch (error) {
      console.error('Error saving wallet information:', error);
    }     


    try {
      console.log("Fetching tokens for address: ", address);

      let allTokenTransfers = [];
      let startBlock = 0;
      let hasMoreResults = true;

      while (hasMoreResults) {
        const config = {
          method: 'get',
          url: ETHERSCAN_API_URL,
          params: {
            module: 'account',
            action: 'tokentx',
            address: address,
            startblock: startBlock,
            endblock: 99999999,
            sort: 'asc',
            apikey: ETHERSCAN_API_KEY
          }
        };

        // Build the full URL
        const fullUrl = new URL(config.url);
        Object.entries(config.params).forEach(([key, value]) => {
          fullUrl.searchParams.append(key, value);
        });

        // Log the full URL
        console.log("Full Etherscan API URL:", fullUrl.toString());

        // Make the request
        const response = await axios(config);
  
        console.log("Etherscan API response:", response.data);
  
        //if (response.data.status !== "1") {
          //throw new Error(`Etherscan API error: ${response.data.message}`);
        //}

        const tokenTransfers = response.data.result;
        allTokenTransfers = [...allTokenTransfers, ...tokenTransfers];

        if (tokenTransfers.length < 1) {
          hasMoreResults = false;
        } else {
          // Set the new startBlock for the next iteration
          startBlock = parseInt(tokenTransfers[tokenTransfers.length - 1].blockNumber) + 1;
        }

        console.log('found more pages, making another request with startBlock set to ', startBlock);
        await new Promise(resolve => setTimeout(resolve, 200));
      } 

      console.log('finished fetching all pages of token transfers. total count: ', allTokenTransfers.length);

      // Function to sanitize and trim strings
      const sanitizeAndTrim = (str) => {
      if (typeof str !== 'string') return '';
      return str
        .replace(/[^\w\s-]/gi, '') // Remove any character that's not alphanumeric, whitespace, or hyphen
        .replace(/\s+/g, '_')      // Replace whitespace with underscores
        .replace(/^[-_]+|[-_]+$/g, '') // Remove leading/trailing hyphens or underscores
        .substring(0, 30)          // Trim to 30 characters
        .replace(/^[\d-_]+/, 'a'); // Ensure it doesn't start with a number, hyphen, or underscore
      };

      // Sanitize and trim token data
      const sanitizedTokenTransfers = allTokenTransfers.map(tx => ({
        ...tx,
        tokenName: sanitizeAndTrim(tx.tokenName),
        tokenSymbol: sanitizeAndTrim(tx.tokenSymbol)
      }));

      const uniqueTokens = [...new Set(sanitizedTokenTransfers.map(tx => tx.contractAddress))];

      console.log("Unique token addresses:", uniqueTokens);
      updateProgress(0, uniqueTokens.length, 'Processing tokens...');
  
      const batchSize = 20;
      const processedAddresses = new Set();
      let processedCount = 0;
      let processedTokensList = [];
  
      for (let i = 0; i < uniqueTokens.length; i += batchSize) {
        const batch = uniqueTokens.slice(i, i + batchSize);
        const batchResults = await processBatch(batch, processedAddresses, sanitizedTokenTransfers);
        processedTokensList = [...processedTokensList, ...batchResults];
        processedCount += batch.length;
        updateProgress(processedCount, uniqueTokens.length, `Processed ${processedCount} of ${uniqueTokens.length} tokens`);
      }

      setProcessedTokens(processedTokensList);
      setTokens(processedTokensList);
      //const filteredTokensList = filterTokens(processedTokensList);

      updateProgress(uniqueTokens.length, uniqueTokens.length, `Token processing complete. Simulating token sells...`);
      const tokensToSimulate = processedTokensList.filter(token => {
        const hasBalance = parseFloat(token.balance) > 0;
        const hasValue = parseFloat(token.value) > 0.0001;
        console.log(`Token ${token.symbol}: balance = ${token.balance}, value = ${token.value}, will simulate: ${hasBalance && hasValue}`);
        return hasBalance && hasValue;
      });

      //currentProcessedTokens = filteredTokensList;
      //setProcessedTokens(currentProcessedTokens);
      //filterTokens(currentProcessedTokens);

      console.log('tokens to simulate: ', tokensToSimulate);

      if (tokensToSimulate.length > 0) {
        console.log(`simulating ${tokensToSimulate.length} tokens...`);
        let currentProcessedTokens = tokensToSimulate;
        console.log('currentProcessedTokens before simulation: ', currentProcessedTokens);
        const simulationResults = await simulateTokens(tokensToSimulate);

        console.log('simulationResults: ', simulationResults);

        if (simulationResults && simulationResults.results && Object.keys(simulationResults.results).length > 0) {
          console.log('Processing simulation results');
          const updatedTokens = currentProcessedTokens.map(token => {
            console.log('Processing simulation result for token:', token.address);
            const simResult = simulationResults.results[token.address.toLowerCase()];
            if (simResult) {
              console.log('simResult for ', token.address, ': ', simResult);
              return {
                ...token,
                sellable: simResult.sellable,
                simulatedAmount: simResult.amount,
                simulationDuration: simResult.duration,
                isFeeOnTransfer: simResult.isFeeOnTransfer,
                routerType: simResult.routerType,
                feeTier: simResult.feeTier
              };
            } else {
              console.log('no simResult for token: ', token.address);  
              return token;
            }
          });
    
          console.log('updatedTokens after simulation:', updatedTokens);
          console.log('updatedTokens length:', updatedTokens.length);

          currentProcessedTokens = updatedTokens;
          setProcessedTokens(currentProcessedTokens);

          console.log('processedTokens BEFORE filtering:', currentProcessedTokens);

          // now re-filter the tokens
          filterTokens(currentProcessedTokens);
        } else {
          console.log('No valid simulation results, keeping original tokens.');
          console.log('simulationResults:', simulationResults);
          setProcessedTokens(currentProcessedTokens);
          filterTokens(currentProcessedTokens);
        }
      } else {
        console.log('no tokens to simulate');
        setProcessedTokens(currentProcessedTokens);
        filterTokens(currentProcessedTokens);
      }

      updateProgress(uniqueTokens.length, uniqueTokens.length, `Finished processing and simulating tokens`);
    } catch (error) {
      console.error("Failed to fetch and simulatetokens:", error);
      updateProgress(0, 0, 'Error fetching and simulating tokens');
    } finally {
      setIsLoading(false);
    }
  }, [address, publicClient, updateProgress]);

  const processBatch = async (batch, processedAddresses) => {
    //const provider = new providers.Web3Provider(publicClient.provider);

    const batchPromises = batch.map(async (tokenAddress) => {
      if (processedAddresses.has(tokenAddress)) return null;
 
      try {
        //const contract = new ethers.Contract(tokenAddress, ERC20_ABI, provider);
        const [balance, decimals, symbol, name] = await Promise.all([
          publicClient.readContract({
            address: tokenAddress,
            abi: ERC20_ABI,
            functionName: 'balanceOf',
            args: [address],
          }),
          publicClient.readContract({
            address: tokenAddress,
            abi: ERC20_ABI,
            functionName: 'decimals',
          }),
          publicClient.readContract({
            address: tokenAddress,
            abi: ERC20_ABI,
            functionName: 'symbol',
          }),
          publicClient.readContract({
            address: tokenAddress,
            abi: ERC20_ABI,
            functionName: 'name',
          }),
        ]);
        const formattedBalance = ethers.utils.formatUnits(balance, decimals);
        
        if (parseFloat(formattedBalance) > 0) {
          const ethValue = await getTokenPrice(tokenAddress, formattedBalance, decimals);
          console.log(`Fetching honeypot data for ${symbol} (${tokenAddress})`);
          const honeypotData = await fetchHoneypotData(tokenAddress,symbol);
          console.log(`honeypotData for ${symbol} (${tokenAddress}): `, honeypotData);

          if (honeypotData && ethValue > 0) {
            processedAddresses.add(tokenAddress);
            return {
              address: tokenAddress,
              symbol,
              balance: formattedBalance,
              value: ethValue,
              decimal: decimals,
              ...honeypotData
            };
          }
        }
      } catch (error) {
        console.error(`Error processing token ${tokenAddress}:`, error);
      }
      return null;
    });
  
    try {
      const batchResults = await Promise.all(batchPromises);
      const validResults = batchResults.filter(result => result !== null);
  
      setProcessedTokens(prev => {
        const uniqueTokens = new Set([...prev, ...validResults].map(JSON.stringify));
        return Array.from(uniqueTokens).map(JSON.parse);
      });
  
      return validResults;
    } catch (error) {
      console.error('Error processing batch:', error);
      return [];
    }
  };

  const handleAccountsChanged = useCallback(async (accounts) => {
    if (accounts.length === 0) {
      // User disconnected their wallet
      disconnectWallet();
    } else {
      const newAddress = accounts[0];
      console.log("Account changed. New address:", newAddress);
     
      // Handle destination wallet update
      if (destinationWallet.toLowerCase() === address.toLowerCase()) {
        // If destination wallet matches the old connected wallet,
        // update it to the new connected wallet
        setDestinationWallet(newAddress);
        saveDestinationWallet(newAddress);
      }
      // If they're different, keep the destination wallet as is     

      // Reset relevant states
      setSelectedTokens({});
      setIsProcessing(false);
      setShowProcessingCard(false);
      setExpectedEthAmount('0');
      setProcessedTokens([]);
 
      // Fetch tokens for the new address
      setIsLoading(true);
      try {
        await fetchTokens();
      } catch (error) {
        console.error("Error fetching tokens for new address:", error);
        toast.error("Failed to load tokens for the new address. Please refresh the page.");
      } finally {
        setIsLoading(false);
      }
  
      // Check for referral token with the new address
      checkReferralToken(newAddress);
    }
  }, [disconnectWallet, fetchTokens, checkReferralToken, address, destinationWallet]);

  const handleChainChanged = useCallback(() => {
    // Reload the page when the chain changes
    window.location.reload();
  }, []);

  const handleSelectAll = useCallback(() => {
    setSelectedTokens(prevSelected => {
      const currentSelectedCount = Object.values(prevSelected).filter(Boolean).length;
      const isAllSelected = currentSelectedCount === Math.min(filteredTokens.length, MAX_SELECTED_TOKENS);
  
      if (isAllSelected) {
        // If all are selected, deselect all
        return {};
      } else {
        // select top max tokens
        const topTokens = filteredTokens.slice(0, MAX_SELECTED_TOKENS);
        const newSelected = {};
        topTokens.forEach(token => {
          newSelected[token.address] = true;
        });
        return newSelected;
      }
    });
  
    // Recalculate expected ETH amount
    const newExpectedEthAmount = filteredTokens.reduce((total, token, index) => {
      if (index < MAX_SELECTED_TOKENS) {
        return total + parseFloat(token.value);
      }
      return total;
    }, 0);
    
    setExpectedEthAmount(newExpectedEthAmount.toFixed(4));
  }, [filteredTokens]);

const handleMinEthChange = (e) => {
  let value = e.target.value;
  
  if (value === '' || value === '0' || value === '0.' || (parseFloat(value) >= 0 && !isNaN(parseFloat(value)))) {
    setMinEth(value === '' ? '0.01' : value);
    
    const filterValue = value === '' || value === '0' || value === '0.' ? 0.01 : parseFloat(value);
    const newFilteredTokens = filterTokens(tokens, filterValue, maxEth, searchText);
    setFilteredTokens(newFilteredTokens);
  }
};

const handleMaxEthChange = (e) => {
  let value = e.target.value;
  
  // Allow empty input to facilitate deletion of ∞
  if (value === '') {
    setMaxEth('');
    const newFilteredTokens = filterTokens(tokens, minEth, Infinity, searchText);
    setFilteredTokens(newFilteredTokens);
    return;
  }

  // Handle ∞ input
  if (value === '∞') {
    setMaxEth('∞');
    const newFilteredTokens = filterTokens(tokens, minEth, Infinity, searchText);
    setFilteredTokens(newFilteredTokens);
    return;
  }

  // Handle numeric input
  if (value === '0' || value === '0.' || (parseFloat(value) >= 0 && !isNaN(parseFloat(value)))) {
    setMaxEth(value);
    const filterValue = parseFloat(value) || Infinity;
    const newFilteredTokens = filterTokens(tokens, minEth, filterValue, searchText);
    setFilteredTokens(newFilteredTokens);
  }
};

const handleSearchChange = (e) => {
    const newSearchText = e.target.value;
    setSearchText(newSearchText);
    const newFilteredTokens = filterTokens(tokens, minEth, maxEth, newSearchText);
    setFilteredTokens(newFilteredTokens);
  };

  useEffect(() => {
    setTokens([]);
    setFilteredTokens([]);
    setSelectedTokens({});
    setProcessedTokens([]);

    if (address) {
      fetchTokens();
    }
  }, [address, fetchTokens]);

  const calculateExpectedEthAmount = useCallback(() => {
    const totalExpectedEth = filteredTokens.reduce((total, token) => {
      if (selectedTokens[token.address]) {
        return total + parseFloat(token.value);
      }
      return total;
    }, 0);
    
    return totalExpectedEth.toFixed(4);
  }, [selectedTokens, filteredTokens]);

  const handleTokenSelection = useCallback((address) => {
    const token = filteredTokens.find(t => t.address === address);
    if (!token || parseFloat(token.balance) === 0 || parseFloat(token.value) === 0) {
      // Don't select tokens with 0 balance or 0 ETH value
      return;
    }
    setSelectedTokens(prevSelected => {
      const isCurrentlySelected = prevSelected[address];
      const currentSelectedCount = Object.values(prevSelected).filter(Boolean).length;
  
      let newSelected;
      if (isCurrentlySelected) {
        // If the token is currently selected, always allow deselection
        const { [address]: _, ...rest } = prevSelected;
        newSelected = rest;
      } else if (currentSelectedCount < MAX_SELECTED_TOKENS) {
        // If the token is not selected and we haven't reached the max, select it
        newSelected = { ...prevSelected, [address]: true };
      } else {
        // If we've reached the max, show a toast notification and don't change the selection
        toast.warning(`You can only select up to ${MAX_SELECTED_TOKENS} tokens at once.`);
        return prevSelected; // Return without changes
      }
  
      // Recalculate expected ETH amount
      const newExpectedEthAmount = filteredTokens.reduce((total, token) => {
        if (newSelected[token.address]) {
          return total + parseFloat(token.value);
        }
        return total;
      }, 0);
      
      setExpectedEthAmount(newExpectedEthAmount.toFixed(4));
      
      return newSelected;
    });
  }, [filteredTokens]);

  const handleTokenItemClick = (event, tokenAddress) => {
    // Ignore clicks on links and checkboxes
    if (event.target.tagName === 'A' || event.target.type === 'checkbox') {
      return;
    }
    
    // Toggle the selected state
    handleTokenSelection(tokenAddress);
  };

  const estimateGas = async (contract, method, args) => {
    try {
      const estimatedGas = await contract.estimateGas[method](...args);
      // start with a 20% buffer
      let buffer = 20;
      // increase buffer if needed
      if (args[0].length > 5) buffer += 5;
      if (args[0].length > 10) buffer += 5;
      return estimatedGas.mul(100 + buffer).div(100);
    } catch (error) {
      console.error('Error estimating gas:', error);
      // If estimation fails, return a default high value
      return ethers.BigNumber.from(1000000);
    }
  };

  const getExpectedOutputWithTax = async (tokenAddress, amount) => {
    const uniswapRouter = new ethers.Contract(UNISWAP_ROUTER_ADDRESS, UNISWAP_ROUTER_ABI, publicClient);
    const path = [tokenAddress, WETH_ADDRESS];
    
    try {
      const amounts = await uniswapRouter.getAmountsOut(amount, path);
      return amounts[1];
    } catch (error) {
      console.error(`Error getting expected output for token ${tokenAddress}:`, error);
      return ethers.BigNumber.from(0);
    }
  };

const getTokensSoldEventData = async (transactionHash) => {
    try {
      const receipt = await publicClient.waitForTransactionReceipt({ hash: transactionHash });

      const eventABI = {
        anonymous: false,
        inputs: [
          { indexed: true, name: "user", type: "address" },
          { indexed: false, name: "actualAmounts", type: "uint256[]" },
          { indexed: false, name: "totalEthReceived", type: "uint256" },
          { indexed: false, name: "userAmount", type: "uint256" },
          { indexed: false, name: "referralAmount", type: "uint256" },
          { indexed: true, name: "referrer", type: "address" },
          { indexed: true, name: "recipient", type: "address" }
        ],
        name: "TokensSold",
        type: "event"
      };

      const ABIinterface = new ethers.utils.Interface([eventABI]);

      // Log all topics for debugging
      console.log('Transaction logs:', receipt.logs);

      const tokensSoldLog = receipt.logs.find(log => 
        log.topics[0] === ABIinterface.getEventTopic('TokensSold')
      );

      if (tokensSoldLog) {
        const decodedLog = ABIinterface.parseLog(tokensSoldLog);
        console.log('Decoded log:', decodedLog);
        const userAmount = decodedLog.args.userAmount;
        const formattedUserAmount = ethers.utils.formatEther(userAmount);
        console.log(`User received: ${formattedUserAmount} ETH`);
        return formattedUserAmount;
      } else {
        console.log("TokensSold event not found in the transaction logs");
        return null;
      }
    } catch (error) {
      console.error("Error fetching TokensSold event data:", error);
      console.log("Error details:", {
        message: error.message,
        stack: error.stack
      });
      throw error;
    }
  };

  
  const sellTokens = async () => {
    if (!walletClient || !isConnected) {
      toast.error("Wallet not connected.");
      return;
    }

    const selectedFilteredTokenAddresses = filteredTokens
      .filter(token => selectedTokens[token.address])
      .map(token => token.address);

    console.log(`You have selected ${selectedFilteredTokenAddresses.length} tokens to sell.`);

    if (selectedFilteredTokenAddresses.length === 0) {
      toast.error('Please select at least one token to sell.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    setShowProcessingCard(true);
    setProgress(0);
    setProcessingStatus('Preparing to sell tokens...');
    setIsProcessing(true);

    try {
      if (!publicClient || !walletClient) {
        throw new Error('Provider or signer is not initialized');
      }

      // Get ETH balance before the transaction
      let balanceBefore;
      try {
        balanceBefore = await publicClient.getBalance({ address });
      } catch (error) {
        console.error('Error getting initial balance:', error);
        balanceBefore = ethers.BigNumber.from(0);
      }

      // Prepare token data for batch sell
      setProgress(20);
      setProcessingStatus('Preparing token data...');
      const tokenAddresses = [];
      const amounts = [];
      const minEthAmounts = [];
      const approvalTransactions = [];
      const isFeeOnTransfer = [];
      const routerTypes = [];
      const feeTiers = [];

      for (const token of filteredTokens) {
        if (selectedTokens[token.address]) {
          tokenAddresses.push(token.address);
    const amount = ethers.utils.parseUnits(token.balance.toString(), token.decimal);
          amounts.push(amount);
          
          // Handle small decimal values correctly
          const tokenValueInWei = ethers.utils.parseEther(token.value.toString());
          const minEthAmount = tokenValueInWei.mul(5000).div(10000); // 50% slippage
          
          minEthAmounts.push(minEthAmount);
          isFeeOnTransfer.push(token.hasFees || false);
          routerTypes.push(token.routerType);
          feeTiers.push(token.feeTier || 3000);
      
          // Check allowance and prepare approval if needed
          const allowance = await publicClient.readContract({
            address: token.address,
            abi: ERC20_ABI,
            functionName: 'allowance',
            args: [address, BATCH_SELL_ADDRESS],
          });
  
          if (ethers.BigNumber.from(allowance.toString()).lt(amount)) {
            console.log(`Preparing approval for token ${token.symbol} (${token.address})`);
            console.log(`Current address: ${address}`);
            approvalTransactions.push({ 
              address: token.address,
              abi: ERC20_ABI,
              functionName: 'approve',
              args: [BATCH_SELL_ADDRESS, ethers.constants.MaxUint256],
              symbol: token.symbol
            });
          }
        }
      }

      let timestamp = Math.floor(Date.now() / 1000) + 300; // Valid for next 5 minutes

      // default referrer is zero address
      let referrer = ethers.constants.AddressZero;
      const storedReferrerAddress = localStorage.getItem('referrerAddress');
      if (storedReferrerAddress && ethers.utils.isAddress(storedReferrerAddress)) {
        referrer = storedReferrerAddress;
        console.log('Using referrer address:', referrer);
      } else {
        console.log('No valid referrer address found, using zero address');
      }

      let signature;

  if (referrer === ethers.constants.AddressZero) {
    signature = '0x';
  } else {
    try {
      signature = await generateSignature(address, referrer, timestamp);

      if (!signature) {
        toast.error('Failed to generate signature. Please try again.');
        return;
      }
    } catch (signError) {
      console.error('Error generating signature:', signError);
      toast.error('Failed to generate signature. Please try again.');
      return;
    }
  }
  
      // Submit approval transactions
      if (approvalTransactions.length > 0) {
        setProgress(40);
        setProcessingStatus(`Approving ${approvalTransactions.length} tokens. Please confirm each transaction.`);

  for (const tx of approvalTransactions) {
      try {
        // Directly send the transaction without simulation
        const hash = await walletClient.writeContract({
    address: tx.address,
    abi: tx.abi,
    functionName: tx.functionName,
    args: tx.args
        });

        setProcessingStatus(prev => `${prev}\nApproval transaction sent for ${tx.symbol}. Hash: ${hash}`);
        
        // Optionally wait for the transaction receipt
        await publicClient.waitForTransactionReceipt({ hash });
        
        setProcessingStatus(prev => `${prev}\nApproval confirmed for ${tx.symbol}`);
      } catch (error) {
        console.error(`Error approving ${tx.symbol}:`, error);
        setProcessingStatus(prev => `${prev}\nFailed to approve ${tx.symbol}: ${error.message}`);
        throw error;
      }
    }

//        for (const tx of approvalTransactions) {
//          try {
//            /const { request } = await publicClient.simulateContract(tx);
//            const hash = await walletClient.writeContract(request);
//            setProcessingStatus(prev => `${prev}\nApproval transaction sent for ${tx.symbol}. Hash: ${hash}`);
//            await publicClient.waitForTransactionReceipt({ hash });
//          } catch (error) {
//            console.error(`Error approving ${tx.symbol}:`, error);
//            setProcessingStatus(prev => `${prev}\nFailed to approve ${tx.symbol}: ${error.message}`);
//            throw error;
//          }
//        }
//
        setProcessingStatus(prev => `${prev}\nAll token approvals complete.`);
      }


      setProgress(50);
      setProcessingStatus('Estimating gas...');
      let gasLimit;

//      try {
//        const { request, result } = await publicClient.simulateContract({
//          address: BATCH_SELL_ADDRESS,
//          abi: BATCH_SELL_ABI,
//          functionName: 'batchSellTokens',
//          args: [tokenAddresses, amounts, minEthAmounts, isFeeOnTransfer, routerTypes, feeTiers, referrer, timestamp, signature],
//          account: address,
//        });
//        gasLimit = ethers.BigNumber.from(result).mul(15).div(10); // 50% buffer
//      } catch (error) {
//        console.error('Error estimating gas:', error);
//        toast.error('Failed to estimate gas. Please try again.');
//        return;
//      }

      // Fetch current gas price
      setProcessingStatus('Fetching current gas price...');
      const fetchedGasPrice = await fetchGasPrice();
      if (fetchedGasPrice === null) {
        toast.error("Failed to estimate gas price. Please try again.");
        return;
      }

      const currentGasPrice = ethers.utils.parseUnits(fetchedGasPrice.toString(), 'gwei');
      const bufferPercentage = 20;
      const bufferedGasPrice = currentGasPrice * (100 + bufferPercentage) / 100;

      // Log the arguments before the contract call
      console.log('batchSellTokens arguments:', {
        tokenAddresses,
        amounts: amounts.map(a => a.toString()),
        minEthAmounts: minEthAmounts.map(a => a.toString()),
        isFeeOnTransfer,
        routerTypes,
        feeTiers,
        referrer,
        timestamp,
        signature,
        destinationWallet
      });

      // Execute batch sell
      setProgress(60);
      setProcessingStatus('Executing batch sell...');
      
   const hash = await walletClient.writeContract({
    address: BATCH_SELL_ADDRESS,
    abi: BATCH_SELL_ABI,
    functionName: 'batchSellTokens',
    args: [tokenAddresses, amounts, minEthAmounts, isFeeOnTransfer, routerTypes, feeTiers, referrer, timestamp, signature, destinationWallet],
    account: address,
    gas: gasLimit, // You might want to estimate this or use a fixed value
    gasPrice: bufferedGasPrice,
  });

  setProgress(80);
  setProcessingStatus('Waiting for transaction confirmation...');
  const receipt = await publicClient.waitForTransactionReceipt({ hash });

      // Analyze results
      setProgress(90);
      setProcessingStatus('Analyzing transaction results...');

      console.log('receipt:', receipt);
      console.log('receipt status:', receipt.status);

 if (receipt.status === 1 || receipt.status === 'success') {
  try {
    const userAmountReceived = await getTokensSoldEventData(receipt.transactionHash);
    if (userAmountReceived) {
      const roundedAmount = parseFloat(userAmountReceived).toFixed(4);
      setProgress(100);
      setProcessingStatus(`Tokens sold successfully. Received ${roundedAmount} ETH.`);
      toast.success(`Successfully sold tokens for ${roundedAmount} ETH!`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      throw new Error('Failed to retrieve TokensSold event data');
    }
  } catch (error) {
    console.error('Error processing TokensSold event:', error);
    setProcessingStatus('Error processing transaction results. Please check your wallet for details.');
    toast.error('Error processing transaction results. Please check your wallet for details.');
  }
} else {
  throw new Error('Transaction failed');
}

      // Refresh token list
      await fetchTokens();
    } catch (error) {
      console.error('Error selling tokens:', error);
      setProcessingStatus(`Error selling tokens: ${error.message}`);
      
      toast.error(`Failed to sell tokens: ${error.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    setSelectedTokens(prevSelected => {
      const newSelected = { ...prevSelected };
      let hasChanged = false;
      
      Object.keys(newSelected).forEach(address => {
        if (!filteredTokens.some(token => token.address === address)) {
          delete newSelected[address];
          hasChanged = true;
        }
      });
      
      return hasChanged ? newSelected : prevSelected;
    });
  }, [filteredTokens]);

  useEffect(() => {
    const newExpectedEthAmount = filteredTokens.reduce((total, token) => {
      if (selectedTokens[token.address]) {
        return total + parseFloat(token.value);
      }
      return total;
    }, 0);
    
    setExpectedEthAmount(newExpectedEthAmount.toFixed(4));
  }, [filteredTokens, selectedTokens]);

  useEffect(() => {
    fetchEthUsdRate();
    const interval = setInterval(fetchEthUsdRate, 60000); // Update every minute
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    console.log('processedTokens updated:', processedTokens);
  }, [processedTokens]);

  useEffect(() => {
    const newExpectedEthAmount = calculateExpectedEthAmount();
    setExpectedEthAmount(newExpectedEthAmount);
  }, [tokens, selectedTokens, calculateExpectedEthAmount]);

  useEffect(() => {
    if (address) {
      checkReferralToken(address);
    }
  }, [checkReferralToken]);

  const truncateAddress = (address) => {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  const sortTokensByEthValue = useCallback((tokens) => {
    return [...tokens].sort((a, b) => parseFloat(b.value) - parseFloat(a.value));
  }, []);

const filterTokens = useCallback((tokens, min, max, search) => {
  console.log('Filtering tokens inside function. minEth:', min, 'maxEth:', max, 'searchText:', search);
  console.log('Number of tokens to filter:', tokens.length);

  const minValue = min === '' ? 0.01 : parseFloat(min) || 0.01;
  const maxValue = max === '' || max === '∞' ? Infinity : parseFloat(max) || Infinity;
  const searchLower = (search || '').toLowerCase();

  const filtered = tokens.filter(token => {
    const tokenValue = parseFloat(token.value);
    const matchesSearch = token.symbol.toLowerCase().includes(searchLower);
    const hasEnoughLiquidity = token.liquidity >= 1000;
    const isNotHoneypot = !token.isHoneypot;
    const hasBalance = parseFloat(token.balance) > 0;
    const hasValue = tokenValue > 0;
    const isSellable = token.sellable !== false;
    const lowTransferTax = parseFloat(token.transferTax) < 10;

    const passes = !isNaN(tokenValue) && 
                   tokenValue >= minValue && 
                   tokenValue <= maxValue && 
                   matchesSearch && 
                   hasEnoughLiquidity && 
                   isNotHoneypot && 
                   hasBalance && 
                   hasValue && 
                   isSellable && 
                   lowTransferTax;

    console.log(`Token ${token.symbol}: value=${tokenValue}, min=${minValue}, max=${maxValue}, matchesSearch=${matchesSearch}, hasEnoughLiquidity=${hasEnoughLiquidity} (current liq: ${token.liquidity}), isNotHoneypot=${isNotHoneypot}, hasBalance=${hasBalance}, hasValue=${hasValue}, isSellable=${isSellable}, lowTransferTax=${lowTransferTax}, passes=${passes}`);
    
    return passes;
  });

  const sortedFilteredTokens = sortTokensByEthValue(filtered);
  setFilteredTokens(sortedFilteredTokens);
  return sortedFilteredTokens;
}, [sortTokensByEthValue]);

  useEffect(() => {
    console.log('minEth changed:', minEth);
  }, [minEth]);

useEffect(() => {
  if (tokens.length > 0) {
    const initialFilteredTokens = filterTokens(tokens, 0.01, Infinity, searchText);
    setFilteredTokens(initialFilteredTokens);
  }
}, [tokens, searchText, filterTokens]);

  const handleReferralToken = async () => {
    if (!address) {
      toast.error('Please connect your wallet first');
      return;
    }
  
    try {
      let response = await axios.get(`/api/get-referral-token/${address}`);
      let token;
  
      if (response.data.referralToken) {
        token = response.data.referralToken;
      } else {
        // Generate new token if it doesn't exist
        response = await axios.post('/api/generate-referral-token', { userAddress: address });
        token = response.data.referralToken;
      }
  
      // Generate referral URL
      const referralUrl = `${APP_LANDING_URL}?ref=${token}`;
  
      // Try to copy to clipboard
      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(referralUrl);
        toast.success('Referral URL copied to clipboard');
      } else {
        // Fallback method
        const textArea = document.createElement("textarea");
        textArea.value = referralUrl;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          const successful = document.execCommand('copy');
          if (successful) {
            toast.success('Referral URL copied to clipboard');
          } else {
            throw new Error('Copy command was unsuccessful');
          }
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
          toast.error('Failed to copy automatically. Please copy this URL manually: ' + referralUrl);
        } finally {
          document.body.removeChild(textArea);
        }
      }
  
      console.log('Your referral URL:', referralUrl);
    } catch (error) {
      console.error('Error handling referral code:', error);
      toast.error('Failed to generate or copy referral URL');
    }
  };

  useEffect(() => {
    if (typeof window.ethereum !== 'undefined') {
      window.ethereum.on('accountsChanged', handleAccountsChanged);
      window.ethereum.on('chainChanged', () => window.location.reload());
    }
  
    return () => {
      if (typeof window.ethereum !== 'undefined') {
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
        window.ethereum.removeListener('chainChanged', () => window.location.reload());
      }
    };
  }, [handleAccountsChanged]);

  useEffect(() => {
    const allFilteredSelected = filteredTokens.every((token) => selectedTokens[token.address]);
    setAllSelected(allFilteredSelected);
  }, [filterTokens, minEth, maxEth, searchText, selectedTokens]);
  
  useEffect(() => {
    console.log('Tokens updated:', tokens);
  }, [tokens]);

  useEffect(() => {
    console.log('filteredTokens updated:', filteredTokens);
  }, [filteredTokens]);

  useEffect(() => {
    setFilteredTokens(prevFilteredTokens => sortTokensByEthValue(prevFilteredTokens));
  }, [sortTokensByEthValue]);

  useEffect(() => {
    if (address) {
      const savedDestination = localStorage.getItem(STORAGE_KEY);
      if (savedDestination) {
        setDestinationWallet(savedDestination);
      } else {
        setDestinationWallet(address);
        saveDestinationWallet(address);
      }
    }
  }, [address]);

  useEffect(() => {
    if (!toastShown.current) {
      checkVipCode();
    }
  }, []);

  return (
    <div className="dustbin-app">
      <Container className="py-5">
        <Card className="main-card">
          <Card.Body>
            <Card.Title className="text-center mb-4">
              <div className="app-header">
                <img
                  src="/img/logo.webp"
                  alt="Dustbin Logo"
                  className="app-logo"
                />
                <h1 className="app-title">THE <span>DUSTBIN</span></h1>
              </div>
              <StatsCounter />
            </Card.Title>
            {!address ? (
              <div className="d-flex justify-content-center pt-4 pb-4">
                <Web3Button icon="show" label="Connect Wallet" balance="show" />
              </div>
            ) : (
              <>
                <Card className="account-card mb-4">
                  <Card.Body>
                    <Row className="align-items-center mb-3">
                      <Col xs={12} md={6} className="d-flex align-items-center mb-3 mb-md-0 gas-gauge-container">
                        <GasGauge gwei={gasPrice} className="me-3" />
                      </Col>
                      <Col xs={12} md={6} className="d-flex flex-column align-items-end">
                        <div className="d-flex align-items-center mb-2">
                          <small className="text-subtext me-2">Connected Wallet:</small>
                          <span className="font-monospace account-address me-3">
                           {truncateAddress(address)}
                           {referrerAddress && (
                             <OverlayTrigger
                               placement="top"
                               overlay={<Tooltip id="referrer-tooltip">Referral Code Applied!</Tooltip>}
                             >
                               <FontAwesomeIcon 
                                 icon={faCoins} 
                                 className="ms-2 text-success" 
                                 style={{ fontSize: '0.8em' }}
                               />
                             </OverlayTrigger>
                           )}
                           {isVip && (
                              <Badge 
                                bg="warning" 
                                text="dark" 
                                className="ms-2" 
                                style={{ fontSize: '0.7em', padding: '0.2em 0.5em' }}
                              >
                                VIP
                              </Badge>
                           )}
                          </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                           <small className="text-subtext me-2">Destination Wallet:</small>
                           <span className="font-monospace account-address me-2">
                             {truncateAddress(destinationWallet)}
                           </span>
                           <OverlayTrigger
                             placement="top"
                             overlay={<Tooltip id="edit-destination-tooltip">Change destination wallet</Tooltip>}
                           >
                             <Button 
                               variant="outline-secondary" 
                               size="sm"
                               onClick={() => {
                                setNewDestinationWallet(destinationWallet);
                                setShowDestinationModal(true);
                               }}
                               className="edit-btn"
                             >
                               <FontAwesomeIcon icon={faPencilAlt} />
                             </Button>
                          </OverlayTrigger>
                          {destinationWallet.toLowerCase() !== address.toLowerCase() && (
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip id="reset-destination-tooltip">Reset to connected wallet</Tooltip>}
                            >
                              <Button 
                                variant="outline-danger" 
                                size="sm"
                                onClick={() => {
                                  setDestinationWallet(address);
                                  saveDestinationWallet(address);
                                  toast.success('Destination wallet has been reset.');
                                }}
                                className="delete-btn ms-2"
                              >
                                <FontAwesomeIcon icon={faTimes} />
                              </Button>
                            </OverlayTrigger>
                          )}
                        </div>
                          <div className="d-flex align-items-center">
                            <OverlayTrigger placement="top" overlay={<Tooltip id="refresh-tooltip">Refresh token list</Tooltip>}>
                              <Button variant="outline-secondary" size="sm" onClick={fetchTokens} disabled={isLoading || isProcessing} className="me-2 refresh-btn">
                                <FontAwesomeIcon icon={faSync} />
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={<Tooltip id="disconnect-tooltip">Disconnect wallet</Tooltip>}>
                              <Button variant="outline-danger" size="sm" onClick={disconnectWallet} className="me-2 disconnect-btn">
                                <FontAwesomeIcon icon={faPowerOff} />
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={<Tooltip id="referral-tooltip">Copy referral link</Tooltip>}>
                              <Button variant="outline-success" size="sm" onClick={handleReferralToken} className="me-2 referral-btn">
                                <FontAwesomeIcon icon={faLink} />
                              </Button>
                            </OverlayTrigger>
                          </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={4} className="mb-2 mb-md-0">
                        <InputGroup className="custom-input-group filter-input-group">
                          <InputGroup.Text>Search</InputGroup.Text>
                          <Form.Control
                            type="text"
                            value={searchText}
                            onChange={handleSearchChange}
                            placeholder="Token name"
                            className="custom-input"
                          />
                        </InputGroup>
                      </Col>
                      <Col xs={12} md={4} className="mb-2 mb-md-0">
                        <InputGroup className="custom-input-group filter-input-group">
                          <InputGroup.Text>Min ETH</InputGroup.Text>
                          <Form.Control
                            type="text"
                            value={minEth}
                            onChange={handleMinEthChange}
                            placeholder="0.01"
                            className="custom-input"
                          />
                        </InputGroup>
                      </Col>
                      <Col xs={12} md={4}>
                        <InputGroup className="custom-input-group filter-input-group">
                          <InputGroup.Text>Max ETH</InputGroup.Text>
                          <Form.Control
                            type="text"
                            value={maxEth}
                            onChange={handleMaxEthChange}
                            placeholder="∞"
                            className="custom-input"
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                <Row className="token-selection-row mb-5">
                  <Col md={6} className="token-column mb-3 mb-md-0">
                    <div className="token-list-container">
                    {isLoading ? (
                      <div className="text-center py-5">
                        <Spinner animation="border" role="status" className="custom-spinner">
                          <span className="visually-hidden">Loading tokens...</span>
                        </Spinner>
                        <br />
                        <h3 className="text-white">Loading Your Dustbin...</h3>
                        <p className="text-white">{loadingProgress.message}</p>
                        {loadingProgress.total > 0 && (
                          <ProgressBar 
                            now={(loadingProgress.current / loadingProgress.total) * 100} 
                            label={`${Math.round((loadingProgress.current / loadingProgress.total) * 100)}%`} 
                            className="mt-3 custom-progress-bar"
                          />
                        )}
                        <div className="mt-3">
                          <p className="text-white-50 small">This could take a few minutes if you have a lot of tokens in your wallet.<br />Some tokens may be excluded from the list due to their low liquidity or other reasons.</p>
                        </div>
                      </div>
                    ) : filteredTokens.length > 0 ? (
                      <>
                        {filteredTokens.length > 0 && (
                          <div className="mb-3 d-flex justify-content-between align-items-center">
                            <div>
                            <Button
                              variant="outline-info"
                              size="sm"
                              onClick={handleSelectAll}
                              className="d-flex align-items-center"
                            >
                              <FontAwesomeIcon 
                                icon={Object.values(selectedTokens).filter(Boolean).length === Math.min(filteredTokens.length, MAX_SELECTED_TOKENS) ? faCheckSquare : faSquare} 
                                className="me-2"
                              />
                              {Object.values(selectedTokens).filter(Boolean).length === Math.min(filteredTokens.length, MAX_SELECTED_TOKENS) ? 'Deselect All' : `Select Max`} ({Object.values(selectedTokens).filter(Boolean).length}/{Math.min(filteredTokens.length, MAX_SELECTED_TOKENS)})
                            </Button>
                            </div>
                            <div className="token-count-box">
                              Tokens Displayed: {filteredTokens.length}
                            </div>
                          </div>
                        )}
                        <ListGroup className="token-list">
                          {filteredTokens.map((token) => (
                            <ListGroup.Item 
                              key={token.address}
                              className={`
                                token-item 
                                d-flex align-items-center justify-content-between 
                                ${selectedTokens[token.address] ? 'selected' : ''}
                                ${Object.values(selectedTokens).filter(Boolean).length >= MAX_SELECTED_TOKENS ? 'max-reached' : ''}
                                ${(parseFloat(token.balance) === 0 || parseFloat(token.value) === 0) ? 'zero-value' : ''}
                              `}
                              onClick={(e) => handleTokenItemClick(e, token.address)}
                            >
                              <div className="d-flex align-items-center">
                                <div>
                                  <strong>{token.symbol}</strong>
                                  <small className="d-block text-subtext">Balance: {parseFloat(token.balance).toLocaleString()}</small>
                                </div>
                              </div>
                              <div className="d-flex align-items-center">
                                <Badge bg="secondary" pill className="token-value me-3">
                                  ≈ {parseFloat(token.value).toFixed(4)} ETH
                                  {ethUsdRate && (
                                    <span> (${ethToUsd(parseFloat(token.value))})</span>
                                  )}
                                </Badge>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip id={`chart-tooltip-${token.address}`}>View chart on Dextools</Tooltip>}
                                >
                                  <a 
                                    href={`https://www.dextools.io/app/ether/pair-explorer/${token.address}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="me-2 token-link"
                                    onClick={(e) => e.stopPropagation()} // Prevent item selection when clicking link
                                  >
                                    <FontAwesomeIcon icon={faChartLine} />
                                  </a>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip id={`etherscan-tooltip-${token.address}`}>View on Etherscan</Tooltip>}
                                >
                                  <a 
                                    href={`https://etherscan.io/token/${token.address}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="token-link"
                                    onClick={(e) => e.stopPropagation()} // Prevent item selection when clicking link
                                  >
                                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                                  </a>
                                </OverlayTrigger>
                              </div>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </>
                      ) : processedTokens.length > 0 ? (
                      <Card className="text-center no-tokens-card h-100">
                        <Card.Body className="d-flex flex-column justify-content-center">
                          <Card.Text>
                            No tokens found with current filter settings.<br />
                            <i className="small">If you think this might be an error, try changing your filter settings or hit the refresh button at the top of the page.</i>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    ) : (
                      <Card className="text-center no-tokens-card h-100">
                        <Card.Body className="d-flex flex-column justify-content-center">
                          <Card.Text>
                            No sellable tokens with any value found in your Dustbin!<br />
                            <i>Your dust ain't worth $#@!%</i><br />
                            <span className="small">If you think this might be an error, hit the refresh button at the top of the page.</span>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    )}
                  </div>
                </Col>
                <Col md={6}>
                  <Card className="h-100 expected-eth-card">
                    <Card.Body className="d-flex flex-column justify-content-center">
                      <h4 className="section-title mb-3 text-center">Total Value</h4>
                      <div className="text-center">
                        <h2 className="mb-0 expected-eth-amount">
                          ≈ {parseFloat(expectedEthAmount).toFixed(4)} ETH
                        </h2>
                        <Badge bg="success" pill>
                          ≈ ${ethToUsd(expectedEthAmount)}
                        </Badge><br /><br />
                        <small className="text-subtext">Estimated value of selected tokens*</small>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <div className="sell-tokens-btn-wrapper mt-3 d-none d-md-block">
                <Button 
                  variant="success" 
                  size="lg" 
                  onClick={sellTokens} 
                  disabled={isLoading || isProcessing}
                  className="sell-tokens-btn"
                >
                  DUST SELECTED TOKENS
                </Button>
              </div>{/*
              <div className="sell-tokens-btn-wrapper-mobile d-md-none">
                <Button 
                  variant="success" 
                  size="lg" 
                  onClick={sellTokens} 
                  disabled={isLoading || isProcessing}
                  className="sell-tokens-btn"
                >
                  DUST SELECTED TOKENS
                </Button>
              </div>
              */}
              {showProcessingCard && (
  <Card className="mt-4 processing-card">
    <Card.Body>
      <div className="d-flex justify-content-between align-items-start">
        <Card.Title className="text-light">Processing Status</Card.Title>
        <Button 
          variant="link" 
          className="p-0 text-light close-btn"
          onClick={() => setShowProcessingCard(false)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </div>
      <ProgressBar 
        now={progress} 
        label={`${progress}%`} 
        className="mb-3 custom-progress-bar" 
      />
                    <div className="d-flex align-items-center mb-2">
                      {isProcessing && (
                        <Spinner 
                          animation="border" 
                          size="sm" 
                          className="me-2 text-light" 
                        />
                      )}
                      <p className="mb-0 text-light">{processingStatus.split('\n')[0]}</p>
                    </div>
                    {processingStatus.split('\n').length > 1 && (
                      <Button 
                        variant="link" 
                        className="p-0 text-info details-toggle"
                        onClick={() => setShowProcessingDetails(!showProcessingDetails)}
                      >
                        {showProcessingDetails ? 'Hide' : 'Show'} Details
                      </Button>
                    )}
                    {showProcessingDetails && (
                      <pre className="mt-2 processing-details text-light">
                        {processingStatus.split('\n').slice(1).join('\n')}
                      </pre>
                    )}
                  </Card.Body>
                </Card>
              )}
            </>
          )}
        </Card.Body>
      </Card>

      <div className="footer-wrapper">
        <p className="service-fee-notice">*{SERVICE_FEE}% service fee will be deducted from the final amount of ETH you receive.</p>
        {referrerAddress && (
          <p className="referrer-notice">Referrer: {truncateAddress(referrerAddress)}</p>
        )}

        <div className="footer-links mt-4 mb-5 text-center">
          <a href="https://t.me/dustbinpro" target="_blank"className="footer-link mx-2">Telegram</a>
          <a href="https://dustbin.gitbook.io/dustbin" target="_blank" className="footer-link mx-2">Gitbook</a>
          <a href="https://twitter.com/DustbinPro" target="_blank" className="footer-link mx-2">Twitter/X</a><br/><br/>
          <span className="small">&copy; 2024 Dustbin.</span><br />
          <span className="small text-white-50">v{dappVersion}</span>
        </div>
      </div>
      <div className="sell-tokens-btn-wrapper-mobile d-md-none">
  <Button 
    variant="success" 
    size="lg" 
    onClick={sellTokens} 
    disabled={isLoading || isProcessing}
    className="sell-tokens-btn"
  >
    DUST SELECTED TOKENS
  </Button>
  </div>
    </Container>

    <Modal show={showDestinationModal} onHide={() => setShowDestinationModal(false)}>
  <Modal.Header closeButton>
    <Modal.Title>Change Destination Wallet</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form>
      <Form.Group>
        <Form.Label>Enter destination wallet address:</Form.Label>
        <Form.Control
          type="text"
          placeholder="0x..."
          value={newDestinationWallet}
          onChange={(e) => setNewDestinationWallet(e.target.value)}
        />
      </Form.Group>
    </Form>
    <span className="text-subtext mt-2 d-block" style={{ fontSize: '0.85em' }}>
      This is the wallet where all your dusted ETH will be delivered to. Please make sure it's correct!
    </span>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowDestinationModal(false)}>
      Cancel
    </Button>
    <Button 
      variant="primary" 
      onClick={() => {
        if (ethers.utils.isAddress(newDestinationWallet)) {
          setDestinationWallet(newDestinationWallet);
          saveDestinationWallet(newDestinationWallet);
          setShowDestinationModal(false);
          setNewDestinationWallet('');
          toast.success('Destination wallet updated successfully');
        } else {
          toast.error('Please enter a valid Ethereum address');
        }
      }}
    >
      Save
    </Button>
  </Modal.Footer>
</Modal>


    <ToastContainer 
      enableMultiContainer={false}
      limit={3} // Limit number of toasts shown at once
      preventDuplicates
      newestOnTop
    />
  </div>
)};


export default Dustbin;
