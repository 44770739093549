import { createConfig, configureChains } from 'wagmi'
import { mainnet } from 'wagmi/chains'
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'

const projectId = '97512e46cc5e64d3be8e2a543ba9be85' // Get this from WalletConnect Cloud

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [mainnet],
  [w3mProvider({ projectId })]
)

export const config = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ chains, projectId }),
  publicClient,
  webSocketPublicClient,
})

export const ethereumClient = new EthereumClient(config, chains)