import axios from 'axios';

class TokenCache {
  static async getToken(address, retries = 3, delay = 1000) {
    try {
      const response = await axios.get(`/api/token/${address}`);
      if (response.data) {
        const lastUpdated = new Date(response.data.last_updated);
        const now = new Date();
        const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);

        console.log('token last updated: ',lastUpdated);
        console.log('date seven days ago: ', sevenDaysAgo);

        if (lastUpdated < sevenDaysAgo) {
          console.log(`token ${address} last updated more than 7 days ago. Fetching new honeypot data...`);
          return null;
        }

        return {
          address: response.data.address,
          symbol: response.data.symbol,
          isHoneypot: response.data.is_honeypot === 1,
          liquidity: parseFloat(response.data.liquidity) || 0,
          routerType: response.data.router_type,
          routerAddress: response.data.router_address,
          feeTier: response.data.fee_tier,
          hasFees: response.data.has_fees === 1,
          isInvalid: response.data.is_invalid === 1,
          sellTax: parseFloat(response.data.sell_tax) || 0,
          buyTax: parseFloat(response.data.buy_tax) || 0,
          transferTax: parseFloat(response.data.transfer_tax) || 0
        };
      }
      return null;
    } catch (error) {
      console.error('Error fetching token:', error);
      if (retries > 0 && (error.code === 'ERR_INSUFFICIENT_RESOURCES' || error.code === 'ECONNABORTED')) {
        console.log(`Retrying fetch for token ${address} in ${delay}ms...`);
        await new Promise(resolve => setTimeout(resolve, delay));
        return TokenCache.getToken(address, retries - 1, delay * 2);
      }
      return null;
    }
  }

  static async saveToken(tokenData) {
    console.log('saving token data! here is the object: ', tokenData);
    try {
      await axios.post(`/api/token`, {
        address: tokenData.address,
        symbol: tokenData.symbol,
        isHoneypot: tokenData.isHoneypot ? 1 : 0,
        value: tokenData.value || 0,
        liquidity: tokenData.liquidity || 0,
        routerType: tokenData.routerType || 0,
        routerAddress: tokenData.routerAddress || '',
        feeTier: tokenData.feeTier || 0,
        hasFees: tokenData.hasFees ? 1 : 0,
        isInvalid: tokenData.isInvalid ? 1 : 0,
        sellTax: tokenData.sellTax || 0,
        buyTax: tokenData.buyTax || 0,
        transferTax: tokenData.transferTax || 0
      });
    } catch (error) {
      console.error('Error saving token:', error);
    }
  }

  static shouldSkipToken(tokenData) {
    return tokenData.isHoneypot || tokenData.liquidity < 1000 || tokenData.isInvalid;
  }
}

export default TokenCache;  
