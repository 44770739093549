import React, { useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGasPump } from '@fortawesome/free-solid-svg-icons';
import * as d3 from 'd3';
import './GasGauge.css';

const GasGauge = ({ gwei }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      drawGauge(gwei);
    }
  }, [gwei]);

  const drawGauge = (gwei) => {
    const el = d3.select(chartRef.current);
    el.selectAll("*").remove(); // Clear previous chart

    const margin = { top: 10, right: 10, bottom: 25, left: 10 };
    const width = 200 - margin.left - margin.right;
    const height = 200 - margin.top - margin.bottom;
    const radius = Math.min(width, height) / 2;
  
    const svg = el.append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom);
  
    const chart = svg.append('g')
      .attr('transform', `translate(${(width + margin.left) / 2}, ${(height + margin.top) / 2})`);

    const numSections = 3;
    const sectionPerc = 1 / numSections / 2;
    const padRad = 0.05;
    const chartInset = 10;
    const barWidth = 40;

    let totalPercent = 0.75;

    const percToDeg = (perc) => perc * 360;
    const percToRad = (perc) => (perc * 360 * Math.PI) / 180;

    // Adjust these values to change the gwei ranges for each color
    const gweiRanges = [15, 25]; // 0-15 green, 15-25 yellow, 25+ red

    for (let sectionIndx = 1; sectionIndx <= numSections; sectionIndx++) {
      const arcStartRad = percToRad(totalPercent);
      const arcEndRad = arcStartRad + percToRad(sectionPerc);
      totalPercent += sectionPerc;

      const startPadRad = sectionIndx === 1 ? 0 : padRad / 2;
      const endPadRad = sectionIndx === numSections ? 0 : padRad / 2;

      const arc = d3.arc()
        .outerRadius(radius - chartInset)
        .innerRadius(radius - chartInset - barWidth)
        .startAngle(arcStartRad + startPadRad)
        .endAngle(arcEndRad - endPadRad);

      chart.append('path')
        .attr('class', `arc chart-color${sectionIndx}`)
        .attr('d', arc);
    }

    class Needle {
      constructor(len, radius) {
        this.len = len;
        this.radius = radius;
      }

      drawOn(el, perc) {
        el.append('circle')
          .attr('class', 'needle-center')
          .attr('cx', 0)
          .attr('cy', 0)
          .attr('r', this.radius);

        el.append('path')
          .attr('class', 'needle')
          .attr('d', this.mkCmd(perc));
      }

      animateOn(el, perc) {
        const self = this;
        el.transition()
          .delay(500)
          .ease(d3.easeElastic)
          .duration(3000)
          .selectAll('.needle')
          .tween('progress', function() {
            return function(percentOfPercent) {
              const progress = percentOfPercent * perc;
              d3.select(this).attr('d', self.mkCmd(progress));
            };
          });
      }

      mkCmd(perc) {
        const thetaRad = percToRad(perc / 2);
        const topX = -this.len * Math.cos(thetaRad);
        const topY = -this.len * Math.sin(thetaRad);
        const leftX = -this.radius * Math.cos(thetaRad - Math.PI / 2);
        const leftY = -this.radius * Math.sin(thetaRad - Math.PI / 2);
        const rightX = -this.radius * Math.cos(thetaRad + Math.PI / 2);
        const rightY = -this.radius * Math.sin(thetaRad + Math.PI / 2);
        return `M ${leftX} ${leftY} L ${topX} ${topY} L ${rightX} ${rightY}`;
      }
    }

    const needle = new Needle(90, 15);
    needle.drawOn(chart, 0);
    
    // Calculate the needle position based on gwei value
    let needlePosition;
    if (gwei <= gweiRanges[0]) {
      needlePosition = (gwei / gweiRanges[0]) * 0.25; // 0-25% of the gauge
    } else if (gwei <= gweiRanges[1]) {
      needlePosition = 0.25 + ((gwei - gweiRanges[0]) / (gweiRanges[1] - gweiRanges[0])) * 0.25; // 25-50% of the gauge
    } else {
      needlePosition = 0.5 + Math.min((gwei - gweiRanges[1]) / gweiRanges[1], 0.5); // 50-100% of the gauge, capped at 100%
    }
    
    needle.animateOn(chart, needlePosition);

    chart.append('text')
      .attr('class', 'gwei-text')
      .attr('text-anchor', 'middle')
      .attr('y', 30)
      .text(`${gwei} Gwei`);
  };

  return <div ref={chartRef} className="chart-gauge"></div>;
};

export default GasGauge;