import React, { useState, useEffect } from 'react';
import axios from 'axios';

const StatsCounter = () => {
  const [stats, setStats] = useState({ totalEthSold: 0, uniqueWallets: 0 });

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get('/api/stats/overall');
        setStats(response.data);
      } catch (error) {
        console.error('Error fetching stats:', error);
      }
    };

    fetchStats();
    // Optionally, set up an interval to refresh stats periodically
    const interval = setInterval(fetchStats, 60000); // Refresh every minute

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="stats-counter">
      A total of <strong>{stats.totalEthSold}</strong> ETH has been recovered from <strong>{stats.uniqueWallets}</strong> different wallets on Dustbin!
    </div>
  );
};

export default StatsCounter;